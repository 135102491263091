.print-banner {
  display: block;
  margin: 0 auto;
  max-width: 960px;
  padding: 20px 10px;
}
.menu-skip,
.header,
.menu-main,
.top-of-content,
.go-to-top,
.menu-cross,
.newsletter,
.stratis,
.section-main__aside,
.filters,
.menu-table-calendar,
.top-bar,
.fixed-bar,
.menu-print,
.site-infos
.site-infos__wrapper {
  display: none !important;
}
.wrapper-940 {
  width: 100%;
  margin: 0;
  padding: 0;
}
.ddm__sub-level {
  display: block !important;
  position: static !important;
  top: inherit !important;
  right: inherit !important;
  bottom: inherit !important;
  left: inherit !important;
  height: auto !important;
  max-height: 100% !important;
}
.list {
  margin-top: 3em;
}
.menu-table-col {
    width: 50%;
    padding: 38px 4px 8px;
    margin: 0 auto;
}

.footer {
  display: none;
}

.menu-table-col {
    width: 20%;
    padding: 40px 4px 20px;
}
